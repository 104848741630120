const axios = require("axios").default;

const axiosBaseQuery = () => async (args) => {
  const { url = "", method = "get", data, params } = args || {};

  try {
    console.log(
      "|| url ",
      url,
      " method: ",
      method,
      " data ",
      data,
      " params ",
      params
    );
    const response = await axios({
      url: url,
      method,
      data,
      params,
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log("|| response: ", response);

    return { data: response.data }; // Return the data from the response
  } catch (error) {
    console.log("|| error: ", error);
    let err = error;
    if (axios.isAxiosError(error)) {
      err = {
        status: error.response?.status,
        message: error.message,
        data: error.response?.data,
      };
    }
    console.log("|| err", err);
    return { error: err }; // Return an error object
  }
};

export default axiosBaseQuery;
