import React, { useEffect } from "react";
import styled from "styled-components";

import {
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Checkbox,
  IconButton,
  Avatar as MuiAvatar,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { blue, green, grey, purple, red, yellow } from "@mui/material/colors";
import { spacing } from "@mui/system";

import { NavLink, useNavigate } from "react-router-dom";

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.ordered && blue[700]};
  color: ${(props) => props.ordered && props.theme.palette.common.white};

  background: ${(props) => props.quoted && grey[700]};
  color: ${(props) => props.quoted && props.theme.palette.common.white};

  background: ${(props) => props.hold && red[700]};
  color: ${(props) => props.hold && props.theme.palette.common.white};

  background: ${(props) => props.accepted && green[700]};
  color: ${(props) => props.accepted && props.theme.palette.common.white};

  background: ${(props) => props.modified && purple[700]};
  color: ${(props) => props.modified && props.theme.palette.common.white};

  background: ${(props) => props.picked && yellow[700]};
  color: ${(props) => props.picked && props.theme.palette.common.white};

  background: ${(props) => props.shipped && grey[400]};
  color: ${(props) => props.shipped && props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Avatar = styled(MuiAvatar)`
  background: light-grey;
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  { id: "name", alignment: "left", label: "Customer" },
  { id: "company_name", alignment: "left", label: "Company" },
  { id: "contact_phone", alignment: "left", label: "Phone" },
  { id: "contact_email", alignment: "left", label: "Email" },
  { id: "shipping_contact_name", alignment: "left", label: "Shipping Contact" },
  { id: "shipping_contact_phone", alignment: "left", label: "Shipping Phone" },
  { id: "shipping_address", alignment: "left", label: "Shipping Address" },
  { id: "billing_contact_name", alignment: "left", label: "Billing Contact" },
  { id: "billing_contact_phone", alignment: "left", label: "Billing Phone" },
  { id: "billing_address", alignment: "left", label: "Billing Address" },
  { id: "view", alignment: "left", label: "View" },
];

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Results
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" size="large">
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list" size="large">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

function CustomerTable(props) {
  const [customer, setCustomer] = React.useState("asc");
  const [orderBy, setQuoteBy] = React.useState("status");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const rows = props.customers || [];

  const navigate = useNavigate();

  useEffect(() => {
    setPage(0);
  }, [props.count]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && customer === "asc";
    setCustomer(isAsc ? "desc" : "asc");
    setQuoteBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    // Fetch more rows if needed
    if (rows.length < rowsPerPage * (newPage + 1)) {
      props.fetchMore();
    }

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar numSelected={selected?.length} />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected?.length}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(customer, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                      selected={isItemSelected}
                      onClick={() => navigate("/customer/" + row.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.company_name}</TableCell>
                      <TableCell align="left">{row.phone}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">
                        {row.shipping_contact_name}
                      </TableCell>
                      <TableCell align="left">{row.shipping_phone}</TableCell>
                      <TableCell align="left">
                        {row.shipping_street_address}
                        <br />
                        {row.shipping_street_address2 ? (
                          <div>
                            {row.shipping_street_address2}
                            <br />
                          </div>
                        ) : (
                          ""
                        )}
                        {row.shipping_city}, {row.shipping_state}
                        <br />
                        {row.shipping_zip}
                      </TableCell>
                      <TableCell align="left">
                        {row.billing_contact_name}
                      </TableCell>
                      <TableCell align="left">{row.billing_phone}</TableCell>
                      <TableCell align="left">
                        {row.billing_street_address}
                        <br />
                        {row.billing_street_address2 ? (
                          <div>
                            {row.billing_street_address2}
                            <br />
                          </div>
                        ) : (
                          ""
                        )}
                        {row.billing_city}, {row.billing_state}
                        <br />
                        {row.billing_zip}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="details"
                          component={NavLink}
                          to={{
                            pathname: "/customer/" + row.id,
                          }}
                          size="large"
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.count || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default CustomerTable;
