import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./axiosBaseQuery";

export const djangoApi = createApi({
  reducerPath: "djangoApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Device"],
  endpoints: (builder) => ({
    getDevices: builder.query({
      query: () => {
        return { url: "devices" };
      },
      providesTags: (result, error, arg) => {
        console.log("|| RESULT : ", result);
        return result
          ? [
              ...result.results.map(({ id }) => ({ type: "Device", id })),
              "Device",
            ]
          : ["Device"];
      },
    }),
    getDevice: builder.query({
      query: (sn) => {
        return { url: "devices/" + sn };
      },
      providesTags: (result, error, arg) => ["Device"],
    }),
    rebootDevice: builder.mutation({
      query: ({ sn, data }) => ({
        url: "devices/" + sn + "/reboot/",
        method: "post",
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Device", id: arg.id }],
    }),
    updateDevice: builder.mutation({
      query: ({ sn, data }) => ({
        url: "devices/" + sn + "/update_firmware/",
        method: "post",
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Device", id: arg.id }],
    }),
    powerOnDevice: builder.mutation({
      query: ({ sn, data }) => ({
        url: "devices/" + sn + "/power_on/",
        method: "post",
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Device", id: arg.id }],
    }),
    powerOffDevice: builder.mutation({
      query: ({ sn, data }) => ({
        url: "devices/" + sn + "/power_off/",
        method: "post",
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Device", id: arg.id }],
    }),
    registerDevice: builder.mutation({
      // Change this to mutation
      query: ({ sn, data }) => ({
        url: `devices/${sn}/register/`,
        method: "post",
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Device", id: arg.id }],
    }),
    modifyDevice: builder.mutation({
      // Change this to mutation
      query: ({ sn, data }) => ({
        url: `devices/${sn}/`,
        method: "patch",
        data: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Device", id: arg.id }],
    }),
    removeDevice: builder.mutation({
      query: ({ sn, data }) => ({
        url: "devices/" + sn + "/remove/",
        method: "post",
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Device", id: arg.id }],
    }),
  }),
});

export const {
  useGetDevicesQuery,
  useGetDeviceQuery,
  usePowerOffDeviceMutation,
  usePowerOnDeviceMutation,
  useRebootDeviceMutation,
  useUpdateDeviceMutation,
  useModifyDeviceMutation,
  useRegisterDeviceMutation,
  useRemoveDeviceMutation,
} = djangoApi;
