import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionPanel,
  AccordionIcon,
  Box,
  HStack,
  Text,
  Badge,
  Icon,
  useBreakpointValue,
  useMediaQuery,
  Typography,
  AccordionDetails,
  Button,
  Grid2,
} from "@mui/material";
import DeviceDetails from "./DeviceDetails";
import { IoLocationOutline } from "react-icons/io5";
import { MdPowerOff, MdPower, MdOutlineQuestionMark } from "react-icons/md";
import { Grid } from "react-feather";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { textAlign } from "@mui/system";

const DeviceAccordionItem = ({ device, commands, handleAction, index }) => {
  const navigate = useNavigate();
  const powerIconColor =
    device.power_state === "ON" ? "rgb(56, 161, 105)" : "rgb(229, 62, 62)";
  const PowerIcon =
    device.power_state === "unknown"
      ? MdOutlineQuestionMark
      : device.power_state === "ON"
      ? MdPower
      : MdPowerOff;

  // const showName =
  //   useMediaQuery((theme) => theme.breakpoints.up("md"), { noSsr: true }) ===
  //   false;
  // const showPowerIcon =
  //   useMediaQuery((theme) => theme.breakpoints.up("sm"), { noSsr: true }) ===
  //   false;
  // const showVersions =
  //   useMediaQuery((theme) => theme.breakpoints.up("lg"), { noSsr: true }) ===
  //   false;

  return (
    <Accordion>
      <AccordionSummary
        // expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Grid2
          container
          spacing={2}
          width="100%"
          // style={{
          //   display: "flex",
          //   alignItems: "center",
          //   justifyContent: "center",
          //   flexDirection: "row",
          //   width: "100%",
          // }}
        >
          <Grid2 item size="grow" style={{ marginRight: "-30px" }}>
            <Button
              onClick={() => {
                navigate(device.serial_number);
              }}
            >
              <Typography variant="h7" fontWeight="bold">
                {device.name}
              </Typography>
            </Button>
          </Grid2>
          <Grid2
            item
            size="grow"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <IoLocationOutline sx={{ display: "flex", alignItems: "center" }} />
            <Typography
              sx={{ width: "33%", flexShrink: 0, marginLeft: 2 }}
              variant="body2"
              color="textSecondary"
            >
              {device.location}
            </Typography>
          </Grid2>
          {/* <div
            style={{
              flex: 1,
              marginRight: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexShrink: 0,
            }}
          > */}
          <Grid2
            item
            size="grow"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <Typography
              backgroundColor={
                device.status === "ONLINE"
                  ? "rgb(198, 246, 213)"
                  : "rgb(254, 215, 215)"
              }
              sx={{
                flex: 1,
                textAlign: "center",
                fontWeight: "bold",
                marginRight: 2,
              }}
            >
              {device.status === "ONLINE"
                ? "Online"
                : device.status === "OFFLINE"
                ? "Offline"
                : "Unknown"}
            </Typography>
            <PowerIcon style={{ minWidth: 15 }} color={powerIconColor} />
            <Typography variant="caption" color="textSecondary">
              HW: {device.hardware_version}
            </Typography>
            <Typography paddingLeft={3} variant="caption" color="textSecondary">
              FW: {device.firmware_version}
            </Typography>
          </Grid2>
          {/* </div> */}
        </Grid2>
      </AccordionSummary>
      {/* <AccordionDetails pb={4}>
        <DeviceDetails
          device={device}
          commands={commands}
          handleAction={handleAction}
        />
      </AccordionDetails> */}
    </Accordion>
  );
};

export default DeviceAccordionItem;
