import React, { useEffect, useState } from "react";
import {
  Box,
  Accordion,
  Heading,
  Skeleton,
  Stack,
  AccordionItem,
  AccordionButton,
  useToast,
  Container,
  useColorModeValue,
  Grid,
  useTheme,
  TextField as MuiTextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import DeviceAccordionItem from "./DeviceAccordionItem";
import { useGetDevicesQuery } from "../../redux/slices/djangoApi";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Toast from "../Toast";
import DeviceDetails from "./DeviceDetails";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { spacing } from "@mui/system";
import { useNavigate } from "react-router-dom";
const TextField = styled(MuiTextField)(spacing);

const AddDevice = () => {
  const toast = Toast();
  const navigate = useNavigate();
  //   const { data: devices, error, isLoading } = useGetDevicesQuery();
  const [registered, setRegistered] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const handleRegister = async () => {
    // todo hit backend to handle register
    const timeout = (delay) => {
      return new Promise((res) => setTimeout(res, delay));
    };
    await timeout(1000).then(() => setRegistered(true));
  };

  const Register = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" paddingBottom={5}>
          This device has not been registered.
        </Typography>
        <Button
          onClick={handleRegister}
          variant="contained"
          color="primary"
          size="large"
        >
          Register Device
        </Button>
      </div>
    );
  };

  const EditDevice = (props) => {
    const Alert = styled(MuiAlert)(spacing);
    const updateDevice = () => {
      console.log("|| todo hit update endpoint");
    };
    return (
      <Formik
        initialValues={{
          serialNumber: "",
        }}
        validationSchema={Yup.object().shape({
          serialNumber: Yup.string(),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            navigate("/devices/" + values.serialNumber);
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              type="serialNumber"
              name="serialNumber"
              label="Serial Number"
              value={values.serialNumber}
              error={Boolean(touched.serialNumber && errors.serialNumber)}
              fullWidth
              helperText={touched.serialNumber && errors.serialNumber}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Start
            </Button>
          </form>
        )}
      </Formik>
    );
  };

  const DeviceDetails = () => {
    const [edit, setEdit] = useState(true);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" paddingBottom={5}>
          Enter your Flipbit serial number
        </Typography>
        <EditDevice />
      </div>
    );
  };

  return (
    <Grid minH="100vh" p={4} mt={4} backgroundColor="white">
      <Container maxW="container.lg" p={8} rounded="lg" shadow="lg">
        <Typography variant="h1" gutterBottom>
          Device
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 100,
          }}
        >
          <DeviceDetails />
        </div>
      </Container>
    </Grid>
  );
};

export default AddDevice;
