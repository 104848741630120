import React, { useEffect, useRef, useState } from "react";
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const DeviceMap = ({ devices }) => {
  const mapRef = useRef();
  const mapContainerRef = useRef();
  const [markers, setMarkers] = useState([]);

  // const devices = [
  //   { id: 1, name: "Place One", coordinates: [-94.006, 42.7128] },
  //   { id: 2, name: "Place Two", coordinates: [-93.935242, 42.73061] },
  //   { id: 3, name: "Place Three", coordinates: [-93.9845, 42.748817] },
  // ];

  useEffect(() => {
    mapboxgl.accessToken =
      "pk.eyJ1IjoidHlsZXJnYWtzdGF0dGVyIiwiYSI6ImNsbnBoc2FwMDBtMnIybXBnanExZGJrdDUifQ.jQBgPfpgB0dQGfRLLCF73g";

    mapRef.current = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [-95.006, 42.7128],
      zoom: 3,
    });

    // Add navigation control (the +/- zoom buttons)
    mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-right");

    return () => {
      mapRef.current.remove();
    };
  }, []);

  useEffect(() => {
    let newMarkers = [];
    let coordinates = [];

    // TODO check if marker not in new device list and remove otherwise move the marker
    markers?.forEach((marker) => {
      marker.remove();
    });

    console.log("|| devices to map: ", devices);
    var newDevices = devices;
    if (!Array.isArray(devices)) {
      newDevices = [devices];
    }
    newDevices?.forEach((device) => {
      console.log("|| device coordinates ", device?.lat, " ", device?.lng);
      if (device?.lat?.length > 1 && device?.lng?.length > 1) {
        coordinates.push([device.lng, device.lat]);
        const marker = new mapboxgl.Marker({ color: "black" })
          .setLngLat([device?.lng, device?.lat])
          .addTo(mapRef.current);
        newMarkers.push(marker);
      }
    });

    if (newDevices?.length > 1 && coordinates?.length > 0) {
      var bounds = coordinates.reduce(function (bounds, coordinates) {
        return bounds.extend(coordinates);
      }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

      mapRef.current.fitBounds(bounds, {
        padding: 50,
      });
    } else if (newDevices?.length == 1 && coordinates?.length == 1) {
      var bounds = coordinates.reduce(function (bounds, coordinates) {
        return bounds.extend(coordinates);
      }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
      mapRef.current.flyTo({
        center: coordinates[0],
        essential: true,
        zoom: 10,
      });
    }

    setMarkers(newMarkers);
  }, [mapRef.current, devices]);

  return (
    <div>
      <Box style={{ height: 300 }}>
        <div
          id="map"
          style={{ height: "100%" }}
          ref={mapContainerRef}
          className="map-container"
        />
      </Box>
    </div>
  );
};

export default DeviceMap;
