import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";
import { djangoApi } from "./slices/djangoApi";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    [djangoApi.reducerPath]: djangoApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(djangoApi.middleware),
});
