import React, { useEffect, useRef, useState } from "react";
import {
  Stack,
  Text,
  Button,
  Stat,
  StatNumber,
  StatHelpText,
  Heading,
  useBreakpointValue,
  useMediaQuery,
  Grid,
  Typography,
  LinearProgress,
  CircularProgress,
  useTheme,
  Card,
  CardContent,
  CardActions,
  Box,
  List,
  ListItem,
  ListItemText,
  TextField,
  Grid2,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetDeviceQuery,
  useModifyDeviceMutation,
} from "../../redux/slices/djangoApi";
import Toast from "../Toast";
import DeviceMap from "./DeviceMap";
import { Container } from "@mui/system";
import * as Yup from "yup";
import { Formik } from "formik";
import { Cancel, CancelOutlined, Close, X } from "@mui/icons-material";
import EditMap from "./EditMap";

const commands = [
  {
    name: "Reboot",
    description: "Reboot the device",
    callback: () => {
      // Add logic to reboot the device
    },
  },
  {
    name: "Toggle Power",
    description: "Toggle the power state of the device",
    callback: () => {
      // Add logic to toggle the power state of the device
    },
  },
  {
    name: "Update Firmware",
    description: "Schedule the power state of the device",
    callback: () => {
      // Add logic to schedule the power state of the device
    },
  },
];

const EditDevice = (props) => {
  const [device, setDevice] = useState(null);
  const { serialNumber } = useParams();
  const {
    data: originalDevice,
    error,
    isLoading,
  } = useGetDeviceQuery(serialNumber);
  const [modifyDevice] = useModifyDeviceMutation();

  const navigate = useNavigate();
  const theme = useTheme();
  const formRef = useRef();

  const backgroundColor =
    theme.palette.mode === "dark"
      ? theme.palette.grey[800]
      : theme.palette.common.white;

  useEffect(() => {
    console.log("|| devices: ", originalDevice);
    if (originalDevice) {
      setDevice(originalDevice);
    }
  }, [originalDevice]);

  const handleAction = (deviceId, callback) => {
    callback();
    Toast({
      title: `Action dispatched to ${deviceId}`,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const showName =
    useMediaQuery((theme) => theme.breakpoints.up("md"), { noSsr: true }) ===
    false;
  const showPowerIcon =
    useMediaQuery((theme) => theme.breakpoints.up("sm"), { noSsr: true }) ===
    false;
  const showVersions =
    useMediaQuery((theme) => theme.breakpoints.up("lg"), { noSsr: true }) ===
    false;

  const handleNewCoords = (newCoords) => {
    console.log("|| newCoords", newCoords);
    if (newCoords) {
      // const newDevice = {
      //   ...device,
      //   lng: Number(newCoords.lng.toFixed(8)),
      //   lat: Number(newCoords.lat.toFixed(8)),
      // };
      // console.log("|| setting device to : ", newDevice);
      // setDevice(newDevice);
      formRef.current.setFieldValue("lng", Number(newCoords.lng.toFixed(8)));
      formRef.current.setFieldValue("lat", Number(newCoords.lat.toFixed(8)));
    }
  };

  // Custom validation for up to 8 decimal places
  const upToEightDecimalPrecision = Yup.number()
    .typeError("Must be a number")
    .test(
      "is-up-to-eight-decimal",
      "Must have up to 8 decimal places",
      (value) => {
        if (value === undefined || value === null) return true; // Allow for optional field
        return /^-?\d+(\.\d{0,8})?$/.test(value);
      }
    );

  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    console.log("|| submitting .... ");

    try {
      await modifyDevice({ sn: serialNumber, data: values }).unwrap();
      console.log("|| finished");
      navigate("/devices/" + serialNumber);
    } catch (error) {
      const message = error.message || "Something went wrong";
      console.log("|| error ", error.message);

      // setStatus({ success: false });
      // setErrors({ submit: message });
      // setSubmitting(false);
    }
  };

  return (
    <Container>
      {!device ? (
        <CircularProgress />
      ) : (
        <div>
          {/* Title, Subtitle, and Description */}

          <Formik
            enableReinitialize
            innerRef={formRef}
            initialValues={{
              name: device?.name || "",
              location: device?.location || "",
              lat: device?.lat || "",
              lng: device?.lng || "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string(),
              location: Yup.string(),
              lat: upToEightDecimalPrecision,
              lng: upToEightDecimalPrecision,
            })}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit}
                <Box mt={4} mb={4}>
                  <Grid2
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Grid2 container>
                      <TextField
                        type="Name"
                        name="name"
                        label="Device Name"
                        value={values.name}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={3}
                      />
                      {/* <TextField
                      label="Device Name"
                      // fullWidth
                      value={device.name}
                      // onChange={(e) => setTitle(e.target.value)}
                      margin="normal"
                    /> */}
                    </Grid2>
                    <Grid2>
                      {props.hideClose ?? (
                        <Close
                          onClick={() => navigate("/devices/" + serialNumber)}
                        />
                      )}
                    </Grid2>
                  </Grid2>

                  <TextField
                    sx={{ marginRight: 4 }}
                    label="Location Name"
                    name="location"
                    error={Boolean(touched.location && errors.location)}
                    helperText={touched.location && errors.location}
                    onBlur={handleBlur}
                    value={values.location}
                    onChange={handleChange}
                    margin="normal"
                  />
                  <TextField
                    sx={{ marginRight: 4, width: 130 }}
                    label="Latitiude"
                    name="lat"
                    error={Boolean(touched.lat && errors.lat)}
                    helperText={touched.lat && errors.lat}
                    onBlur={handleBlur}
                    value={values.lat}
                    onChange={handleChange}
                    margin="normal"
                  />
                  <TextField
                    sx={{ marginRight: 4, width: 130 }}
                    label="Longitude"
                    name="lng"
                    error={Boolean(touched.lng && errors.lng)}
                    helperText={touched.lng && errors.lng}
                    onBlur={handleBlur}
                    value={values.lng}
                    onChange={handleChange}
                    margin="normal"
                  />
                </Box>
              </form>
            )}
          </Formik>
          {/* Map for displaying device locations */}
          <EditMap
            updateCoordinates={handleNewCoords}
            devices={[originalDevice]}
          />
          {/* <Typography variant="h6" mt={4}>
            Shared With
          </Typography>
          <List>
            {Array.from({ length: 4 }).map((_, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={`User ${index + 1}`}
                  secondary={`Date shared: 12/1/2012 ${index + 1}`}
                />
              </ListItem>
            ))}
          </List> */}
          <Button
            style={{ marginTop: 4 }}
            onClick={() => {
              if (formRef.current) {
                formRef.current.handleSubmit();
              }
            }}
          >
            Submit
          </Button>
        </div>
      )}
    </Container>
  );
};

export default EditDevice;
