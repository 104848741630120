import React from "react";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import Page404 from "./pages/auth/Page404";

// Components

// DMG Pages
import DevicesList from "./pages/device/DevicesList";
import RegisterDevice from "./pages/device/RegisterDevice";

// Form components

// Icon components

// Page components

// Table components

// Documentation

// Landing

// Protected routes
import CreateCustomer from "./pages/RMA/customers/CreateCustomer";
import CustomerList from "./pages/RMA/customers/CustomerList";
import CustomerEdit from "./pages/RMA/customers/edit";
import OrderCreator from "./pages/RMA/orders/OrderCreator";
import OrderDetail from "./pages/RMA/orders/OrderDetails";
import OrderList from "./pages/RMA/orders/OrderList";
import Quote from "./pages/RMA/quotes/Quote";
import QuoteCreator from "./pages/RMA/quotes/QuoteCreator";
import QuoteList from "./pages/RMA/quotes/QuoteList";
import AddDevice from "./pages/device/AddDevice";
import DeviceDetails from "./pages/device/DeviceDetails";
import Device from "./pages/device/Device";
import EditDevice from "./pages/device/EditDevice";
import { Navigate } from "react-router-dom";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Navigate replace to="/devices" />,
      },
    ],
  },
  {
    path: "devices",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DevicesList />,
      },
      {
        path: "add",
        element: <AddDevice />,
      },
      {
        path: ":serialNumber",
        children: [
          {
            path: "",
            element: <Device />,
          },
          {
            path: "register",
            element: <RegisterDevice />,
          },
          {
            path: "edit",
            element: <EditDevice />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
