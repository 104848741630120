import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";

import {
  Grid,
  LinearProgress,
  Button as MuiButton,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField,
  Typography,
} from "@mui/material";

import { spacing } from "@mui/system";

import SearchIcon from "@mui/icons-material/Search";
import { CartContext } from "../../../contexts/CartProvider.js";
import { addToCart } from "../../../contexts/cartReducer.js";

import Item from "./Item.js";

const axios = require("axios").default;

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Button = styled(MuiButton)(spacing);

function ProductList({ handleSelection }) {
  const { dispatch } = useContext(CartContext);

  const [isLoading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [nextUrl, setNextUrl] = React.useState(null);
  const [total, setTotal] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState("");
  const timerRef = React.useRef();

  const scrollRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    setRows([]);
    axios
      .get("/products/" + "?search=" + searchQuery)
      .then((response) => {
        setRows(response.data.results);
        setTotal(response.data.count);
        setNextUrl(response.data.next);
      })
      .then(() => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [searchQuery]);

  const fetchMore = () => {
    axios
      .get(nextUrl)
      .then((response) => {
        setLoading(false);
        setRows((existingRows) => [...existingRows, ...response.data.results]);
        setTotal(response.data.count);
        setNextUrl(response.data.next);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  const addToCartHandler = (product) => {
    if (handleSelection != undefined) {
      handleSelection(product);
    } else {
      dispatch(addToCart(product));
    }
  };

  const handleScroll = (event) => {
    setScrollPosition(event.target.scrollTop);
  };

  const searchbox = {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(255, 255, 255, 0.0)",
    boxShadow: "rgba(0, 0, 0, 0.0) 0px 5px 15px",
    padding: 8,
    marginTop: 10,
    borderRadius: 10,
    flex: 1,
    display: "flex",
    alignItems: "center",
    width: "90%",
    margin: "auto",
    transition: "background-color 1s linear",
    transition: "box-shadow 1s linear",
  };

  const searchboxshadow = {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    padding: 8,
    marginTop: 10,
    borderRadius: 10,
    flex: 1,
    display: "flex",
    alignItems: "center",
    width: "90%",
    margin: "auto",
    transition: "background-color 0.20s linear",
    transition: "box-shadow 1s linear",
  };

  const searchboxwrapper = {
    width: "100%",
    top: 15,

    position: "absolute",
    zIndex: 99,
  };

  const wrapper = {
    position: "relative",
    width: "100%",
    // backgroundColor: "pink",
  };

  const scrollablediv = {
    position: "absolute",
    top: 0,
    width: "100%",
    // backgroundColor: "pink",
    height: "750px",
    zIndex: 1,
  };

  return (
    <div style={wrapper}>
      <Helmet title="Products" />
      <div style={searchboxwrapper}>
        <div style={scrollPosition < 50 ? searchbox : searchboxshadow}>
          <SearchIcon style={{ marginRight: 10, fontSize: "32px" }} />
          <TextField
            id="search-bar"
            className="text"
            autoFocus
            onInput={(e) => {
              clearTimeout(timerRef.current);
              timerRef.current = setTimeout(() => {
                setSearchQuery(e.target.value);
              }, 300);
            }}
            label="Search Products"
            fullWidth
            sx={{ m: 1 }}
            variant="standard"
            placeholder="e.g. Eos Arrow"
            inputProps={{ style: { fontSize: 20 } }} // font size of input text
            InputLabelProps={{ style: { fontSize: 15 } }}
          />
        </div>
      </div>

      <div id="scrollableDiv" style={scrollablediv}>
        <InfiniteScroll
          style={{ paddingTop: 110 }}
          dataLength={rows.length}
          next={() => fetchMore()}
          height={750} // this needs to be set and the container height needs to be set!
          scrollableTarget="scrollableDiv"
          hasMore={!!nextUrl} // Replace with a condition based on your data source
          loader={<LinearProgress />}
          onScroll={handleScroll}
          endMessage={
            <div style={{ width: "100%", marginTop: "15" }}>
              <center>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginBottom: 10, marginTop: 10 }}
                >
                  End of products
                </Typography>
              </center>
            </div>
          }
          scrollThreshold={0.8}
        >
          <Grid container spacing={3}>
            {rows?.map((item) => (
              <Grid
                item
                key={item.id}
                xs={12}
                sm={6}
                md={3}
                lg={2}
                xl={2}
                style={{ width: "100%" }}
              >
                <Item item={item} handleAddToCart={addToCartHandler} />
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>
        <div style={{ width: "100%" }}>
          <center>
            {nextUrl && (
              <Button
                style={{ marginTop: 10 }}
                type="submit"
                variant="contained"
                color="primary"
                disabled={false}
                onClick={() => fetchMore()}
              >
                Load More Products
              </Button>
            )}
          </center>
        </div>
      </div>
    </div>
  );
}

export default ProductList;
