import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import { Add as AddIcon } from "@mui/icons-material";
import {
  Button,
  Grid,
  LinearProgress,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

import useAuth from "../../../hooks/useAuth.js";
import Page404 from "../../../pages/auth/Page404";
import QuoteTable from "./QuoteTable.js";

const axios = require("axios").default;

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function QuoteList(props) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [quotes, setQuotes] = React.useState([]);
  const [nextUrl, setNextUrl] = React.useState(null);
  const [total, setTotal] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState("");
  const timerRef = React.useRef();
  useEffect(() => {
    setLoading(true);
    // setQuotes([]);
    var url =
      "/quotes/" +
      (props?.status ? props.status + "/" : "") +
      "?search=" +
      searchQuery;
    axios
      .get(url)
      .then((response) => {
        setQuotes(response.data.results);
        setTotal(response.data.count);
        setNextUrl(response.data.next);
      })
      .then(() => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [searchQuery, props?.status]);

  const fetchMore = () => {
    if (nextUrl === null) {
      return;
    }

    setLoading(true);

    var url = nextUrl;
    axios
      .get(url)
      .then((response) => {
        setQuotes((existingQuotes) => [
          ...existingQuotes,
          ...response.data.results,
        ]);
        setTotal(response.data.count);
        setNextUrl(response.data.next);
      })
      .then(() => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  if (user?.quoting_enabled === false) {
    return <Page404 />;
  } else {
    return (
      <React.Fragment>
        <Helmet title="Quotes" />

        <Grid justifyContent="space-between" container spacing={10}>
          <Grid item>
            <Typography variant="h3" gutterBottom display="inline">
              Quotes
            </Typography>
          </Grid>
          <Grid item>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/quote/create")}
              >
                <AddIcon />
                Create Quote
              </Button>
            </div>
          </Grid>
        </Grid>

        <Divider my={6} />

        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "white",
            padding: 0,
            marginTop: 10,
            // marginLeft: 40,
            alignItems: "end",
            // borderRadius: 4,
            paddingBottom: 5,
          }}
          justifyContent="space-between"
          container
          // spacing={10}
        >
          <SearchIcon style={{ marginBottom: 10, marginLeft: 5 }} />
          <div style={{ flex: 1, paddingRight: 20, paddingLeft: 10 }}>
            <TextField
              id="search-bar"
              className="text"
              onInput={(e) => {
                clearTimeout(timerRef.current);
                timerRef.current = setTimeout(() => {
                  setSearchQuery(e.target.value);
                }, 300);
              }}
              label="Search Quotes"
              fullWidth
              sx={{ m: 1 }}
              variant="standard"
              placeholder="Search..."
              size="small"
              inputProps={{ style: { fontSize: 18 } }} // font size of input text
              InputLabelProps={{ style: { fontSize: 18 } }}
            />
          </div>
        </Grid>

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <QuoteTable quotes={quotes} count={total} fetchMore={fetchMore} />
          </Grid>
        </Grid>
        {loading && <LinearProgress />}
      </React.Fragment>
    );
  }
}

export default QuoteList;
