import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Accordion,
  Heading,
  Skeleton,
  Stack,
  AccordionItem,
  AccordionButton,
  useToast,
  Container,
  useColorModeValue,
  useTheme,
  TextField as MuiTextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Typography,
  Button,
  CircularProgress,
  Grid2,
} from "@mui/material";
import DeviceAccordionItem from "./DeviceAccordionItem";
import {
  useRegisterDeviceMutation,
  useGetDeviceQuery,
} from "../../redux/slices/djangoApi";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Toast from "../Toast";
import DeviceDetails from "./DeviceDetails";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { display, spacing } from "@mui/system";
import { borderRadius } from "polished";
import WorldAnimation from "../../vendor/WorldAnimation.gif";
import PlugAnimation from "../../vendor/PlugAnimation.gif";
import { useNavigate, useParams } from "react-router-dom";
import EditMap from "./EditMap";
import EditDevice from "./EditDevice";
const axios = require("axios").default;

const TextField = styled(MuiTextField)(spacing);

const RegisterDevice = () => {
  const toast = Toast();
  const params = useParams();

  const { data, error, isLoading } = useGetDeviceQuery(params.serialNumber);

  const [registerDevice, {}] = useRegisterDeviceMutation();

  const [registered, setRegistered] = useState(false);

  const handleRegister = async () => {
    await registerDevice({ sn: params?.serialNumber, data: {} })
      .then((response) => {
        console.log("|| register result", response);
        if ((response.status = "device registered!")) {
          setRegistered(true);
        }
      })
      .catch(function (e) {
        console.log(e);
      });
  };

  const Register = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Grid2
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h3" paddingBottom={5}>
            WELCOME!
          </Typography>

          {/* <img
          src={PlugAnimation}
          alt="plug"
          style={{
            height: 150,
            width: 150,
            borderRadius: "1em",
            marginTop: "1em",
          }}
        /> */}
        </Grid2>
        <Typography variant="h6" paddingBottom={5}>
          You are on your way to managing your device remotely!
        </Typography>
        <Typography variant="h6" paddingBottom={5}>
          To begin, register this device to your account.
        </Typography>
        <Typography variant="h6" paddingBottom={3}>
          By registering, you can easily monitor and control your device from
          anywhere, anytime. Enjoy seamless connectivity!
        </Typography>
        <Typography variant="h6" paddingBottom={5}>
          You are on your way to managing your device remotely! To begin,
          register this device to your account.
        </Typography>
        <img
          src={WorldAnimation}
          alt="world"
          style={{
            alignSelf: "center",
            height: 150,
            width: 150,
            margin: -50,
            marginBottom: -30,
            borderRadius: "1em",
          }}
        />
        <Button
          onClick={handleRegister}
          variant="contained"
          color="primary"
          size="large"
          sx={{ width: 150, alignSelf: "center" }}
        >
          Register Device
        </Button>
      </div>
    );
  };

  const Details = () => {
    const [edit, setEdit] = useState(false);
    const Alert = styled(MuiAlert)(spacing);
    const navigate = useNavigate();
    const formRef = useRef();
    const updateDevice = () => {
      console.log("|| todo hit update endpoint");
    };

    return (
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignSelf: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div>
              <Typography variant="h6" paddingBottom={5}>
                This device has been registered to your account.
              </Typography>
              <Typography variant="h6" paddingBottom={5}>
                Finish filling out some details about the device below.
              </Typography>
            </div>
            <img
              src={PlugAnimation}
              alt="plug"
              style={{
                alignSelf: "center",
                height: 150,
                width: 150,
                borderRadius: "1em",
                marginTop: "-20px",
              }}
            />
          </div>
          <EditDevice hideClose={true}></EditDevice>

          {/* <Formik
            enableReinitialize
            innerRef={formRef}
            initialValues={{
              name: "",
              lat: "",
              lng: "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(25),
              lat: Yup.string().max(255),
              lng: Yup.string().max(255),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                console.log("|| submitting values: ", values);
                // updateDevice(values);
                axios
                  .patch("devices/" + params?.serialNumber + "/", {
                    data: { ...values },
                  })
                  .then((response) => {
                    console.log("|| submit result", response);
                    if (true) {
                      navigate("/devices/" + params?.serialNumber);
                      setEdit(true); // todo switch to false
                      setRegistered(true);
                    }
                  })
                  .catch(function (e) {
                    console.log(e);
                  });
              } catch (e) {
                const message = e.message || "Something went wrong";

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                <TextField
                  type="Name"
                  name="name"
                  label="Device Name"
                  value={values.name}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Grid2 sx={{ display: "flex", flexDirection: "row" }}>
                  <TextField
                    type="lat"
                    name="lat"
                    label="Latitude"
                    placeholder="XX.XXXXXXX"
                    value={values.lat}
                    error={Boolean(touched.lat && errors.lat)}
                    fullWidth
                    helperText={touched.lat && errors.lat}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={4}
                    mr={2}
                  />
                  <TextField
                    type="lng"
                    name="lng"
                    label="Longitude"
                    placeholder="(-)XXX.XXXXXXX"
                    value={values.lng}
                    error={Boolean(touched.lng && errors.lng)}
                    fullWidth
                    helperText={touched.lng && errors.lng}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={4}
                  />
                </Grid2>
              </form>
            )}
          </Formik>
        </div>

        <div>
          <EditMap devices={[{}]} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            // disabled={isSubmitting}
            onClick={() => formRef?.current?.submit()}
          >
            Save Edits
          </Button>
        </div> */}
        </div>
      </div>
    );
  };

  return (
    <Grid2 minH="100vh" p={4} mt={4} backgroundColor="white">
      <Container maxW="container.lg" p={8} rounded="lg" shadow="lg">
        <Typography variant="h1" gutterBottom>
          Device
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {error ? (
            <Typography>
              Device Not Found. Are you sure the Serial Number is right?
            </Typography>
          ) : isLoading ? (
            <CircularProgress />
          ) : registered ? (
            <Details />
          ) : (
            <Register />
          )}
        </div>
      </Container>
    </Grid2>
  );
};

export default RegisterDevice;
