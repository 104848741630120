import React, { useEffect, useState } from "react";
import {
  Stack,
  Text,
  Button,
  Stat,
  StatNumber,
  StatHelpText,
  Heading,
  useBreakpointValue,
  useMediaQuery,
  Grid2,
  Typography,
  LinearProgress,
  CircularProgress,
  useTheme,
  Card,
  CardContent,
  CardActions,
  Box,
  List,
  ListItem,
  ListItemText,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetDeviceQuery,
  useGetDevicesQuery,
  usePowerOffDeviceMutation,
  usePowerOnDeviceMutation,
  useRebootDeviceMutation,
  useRemoveDeviceMutation,
  useUpdateDeviceMutation,
} from "../../redux/slices/djangoApi";
import Toast from "../Toast";
import DeviceMap from "./DeviceMap";
import { Container } from "@mui/system";
import { Power, PowerOff, Settings } from "@mui/icons-material";
import { format } from "date-fns";

// const commands = [
//   {
//     name: "Reboot",
//     description: "Reboot the device",
//     callback: () => {
//       // Add logic to reboot the device
//     },
//   },
//   {
//     name: "Toggle Power",
//     description: "Toggle the power state of the device",
//     callback: () => {
//       // Add logic to toggle the power state of the device
//     },
//   },
//   {
//     name: "Update Firmware",
//     description: "Schedule the power state of the device",
//     callback: () => {
//       // Add logic to schedule the power state of the device
//     },
//   },
// ];

const Device = () => {
  // const [device, setDevice] = useState(null);
  const { serialNumber } = useParams();
  const { data: device, error, isLoading } = useGetDeviceQuery(serialNumber);
  const [open, setOpen] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [timeElapsed, setTimeElapsed] = useState("");
  const [connectedStart, setConnectedStart] = useState(null);
  const [powerState, setPowerState] = useState(null);

  const [actionToConfirm, setActionToConfirm] = useState(false);
  const [rebootDevice, {}] = useRebootDeviceMutation();
  const [updateDevice, {}] = useUpdateDeviceMutation();
  const [powerOnDevice, {}] = usePowerOnDeviceMutation();
  const [powerOffDevice, {}] = usePowerOffDeviceMutation();
  const [removeDevice, {}] = useRemoveDeviceMutation();

  const navigate = useNavigate();
  const theme = useTheme();
  const backgroundColor =
    theme.palette.mode === "dark"
      ? theme.palette.grey[800]
      : theme.palette.common.white;

  const Uptime = ({ startTime }) => {
    // Function to calculate time elapsed
    const calculateTimeElapsed = (beginning) => {
      const start = new Date(beginning);
      const now = new Date();
      const diffInMs = now - start; // Difference in milliseconds

      const seconds = Math.floor(diffInMs / 1000) % 60;
      const minutes = Math.floor(diffInMs / 60000) % 60;
      const hours = Math.floor(diffInMs / 3600000) % 24;
      const days = Math.floor(diffInMs / 86400000);

      return `${days} days, ${hours} hours: ${minutes} minutes: ${seconds} seconds`;
    };

    useEffect(() => {
      if (!startTime) return;

      const interval = setInterval(() => {
        setTimeElapsed(calculateTimeElapsed(startTime));
      }, 1000);

      return () => clearInterval(interval); // Clean up on component unmount
    }, [startTime]);

    return (
      <Typography variant="body2" style={{ minHeight: 20 }}>
        {startTime ? timeElapsed : ""}
      </Typography>
    );
  };

  useEffect(() => {
    console.log("|| device?.status", device?.status);
    if (device?.status === "device not registered!") {
      console.log("|| navigate to registration");
      navigate("register");
    }
    const contactDate = new Date(device?.last_contact_time);
    const timeSince = new Date(contactDate - device?.runtime * 1000);
    setConnectedStart(timeSince);
  }, [device]);

  useEffect(() => {
    if (error) {
      console.log("Error", error);
      navigate("register");
    }
  }, [error]);

  const SimpleDialog = (props) => {
    const { onClose, open } = props;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirm(false)}>Cancel</Button>
          <Button onClick={() => handleConfirm(true)} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // const handleAction = (deviceId, callback) => {
  //   callback();
  //   Toast({
  //     title: `Action dispatched to ${deviceId}`,
  //     status: "success",
  //     duration: 2000,
  //     isClosable: true,
  //   });
  // };

  const showName =
    useMediaQuery((theme) => theme.breakpoints.up("md"), { noSsr: true }) ===
    false;
  const showPowerIcon =
    useMediaQuery((theme) => theme.breakpoints.up("sm"), { noSsr: true }) ===
    false;
  const showVersions =
    useMediaQuery((theme) => theme.breakpoints.up("lg"), { noSsr: true }) ===
    false;

  const handleConfirm = (event, reason) => {
    if ((reason && reason === "backdropClick") || !event) {
      setOpen(false);
      setActionToConfirm(null);
      return;
    }
    if (actionToConfirm === "reboot") {
      handleReboot({ sn: serialNumber });
    }
    if (actionToConfirm === "updateFirmware") {
      handleFirmwareUpdate();
    }
    if (actionToConfirm === "powerOff") {
      handlePowerOff();
    }
    if (actionToConfirm === "powerOn") {
      handlePowerOn();
    }
    if (actionToConfirm === "remove") {
      handleRemove();
    }
    setOpen(false);
    setActionToConfirm(null);
  };

  const handleReboot = () => {
    console.log("Rebooting the device...");
    rebootDevice({ sn: serialNumber });
  };

  const handleFirmwareUpdate = () => {
    console.log("Updating the device...");
    updateDevice({ sn: serialNumber });
  };
  const handlePowerOn = () => {
    console.log("Turning power on...");
    powerOnDevice({ sn: serialNumber });
  };
  const handlePowerOff = () => {
    console.log("Turning power off...");
    powerOffDevice({ sn: serialNumber });
  };
  const handleRemove = () => {
    console.log("Removing device...");
    removeDevice({ sn: serialNumber }).then(() => navigate("/devices"));
  };

  const handleToggleChange = (e, newState) => {
    console.log("changing toggle event:", e, " new state: ", newState);
    if (newState == "OFF") {
      setDialogText("Are you sure you'd like to turn the power off?");
      setDialogTitle("Power Off");
      setActionToConfirm("powerOff");
      setOpen(true);
    }
    if (newState == "ON") {
      setDialogText("Are you sure you'd like to turn the power off?");
      setDialogTitle("Power Off");
      setActionToConfirm("powerOff");
      setOpen(true);
    }
  };

  return (
    <Container>
      {!device ? (
        <div style={{ paddingTop: 100 }}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          {/* Title, Subtitle, and Description */}
          <SimpleDialog open={open} onClose={handleConfirm} />
          <Box mt={4} mb={4}>
            <Grid2
              container
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Grid2 item>
                <Typography variant="h3" gutterBottom>
                  {device?.name}
                </Typography>
                <Typography variant="h5" gutterBottom ml={4} alignSelf="center">
                  {device?.topic}
                </Typography>
              </Grid2>
              <Grid2 item>
                <Settings onClick={() => navigate("edit")} />
              </Grid2>
            </Grid2>
            <Grid2 container>
              <Typography variant="body1" mr={4}>
                {device?.location}:
              </Typography>
              <Typography variant="body1">
                [{device?.lat}, {device?.lng}]
              </Typography>
            </Grid2>
          </Box>

          {/* Map for displaying device locations */}
          <DeviceMap devices={device} />

          {/* Row of Action Buttons */}
          <Box mt={4} mb={4}>
            <Button
              variant="contained"
              color="primary"
              sx={{ mr: 2, mb: 2 }}
              onClick={() => {
                setDialogText(
                  "Are you sure you want to cycle power off and back on from the device outlet?"
                );
                setDialogTitle("Reboot Power Out");
                setActionToConfirm("reboot");
                setOpen(true);
              }}
            >
              Reboot
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ mr: 2, mb: 2 }}
              onClick={() => {
                setDialogText(
                  "Are you sure you'd like to update the devices firmware?"
                );
                setDialogTitle("Update Firmware");
                setActionToConfirm("updateFirmware");
                setOpen(true);
              }}
            >
              Update Firmware
            </Button>
            {/* <Button
              variant="contained"
              disabled
              color="primary"
              sx={{ mr: 2, mb: 2 }}
              onClick={() => {
                setDialogText("Choose Who you'd like to share with");
                setDialogTitle("Share device?");
                setActionToConfirm("shareWith");
                setOpen(true);
              }}
            >
              Share with user
            </Button> */}
            <ToggleButtonGroup
              value={device?.power_state}
              disabled={device?.status !== "ONLINE"}
              onChange={handleToggleChange}
              exclusive
              aria-label="power"
              sx={{
                mr: 2,
                mb: 2,
                height: 36,
                verticalAlign: "bottom",
                minWidth: 180,
              }}
            >
              <ToggleButton value="ON" aria-label="power on" sx={{ flex: 1 }}>
                <Power />
              </ToggleButton>
              <ToggleButton
                value="OFF"
                aria-label="power off"
                sx={{ flex: 1 }}
                disabled={!device?.enable_power_off}
              >
                <PowerOff />
              </ToggleButton>
            </ToggleButtonGroup>

            <Button
              variant="contained"
              color="error"
              sx={{ mb: 2 }}
              onClick={() => {
                setDialogText(
                  "WARNING! Are you sure you'd like to remove the device from your account?"
                );
                setDialogTitle("Remove Device");
                setActionToConfirm("remove");
                setOpen(true);
              }}
            >
              Remove Device
            </Button>
          </Box>

          {/* Two Cards in a Horizontal Row */}
          <Grid2 container spacing={2} mb={4}>
            <Grid2 item xs={6} size="grow">
              <Card>
                <CardContent>
                  <Typography variant="h6">Status</Typography>
                  <Typography
                    backgroundColor={
                      device.status === "ONLINE"
                        ? "rgb(198, 246, 213)"
                        : "rgb(254, 215, 215)"
                    }
                    sx={{
                      width: "100px",
                      textAlign: "center",
                      fontWeight: "bold",
                      marginRight: 2,
                    }}
                  >
                    {device.status === "ONLINE"
                      ? "Online"
                      : device.status === "OFFLINE"
                      ? "Offline"
                      : "Unknown"}
                  </Typography>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Power
                  </Typography>
                  <Typography variant="body2">
                    {device?.power_state == "OFF"
                      ? "Off"
                      : device?.power_state == "ON"
                      ? "On"
                      : "Unknown"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid2>
            <Grid2 item xs={6} size="grow">
              <Card>
                <CardContent>
                  <Typography variant="h6">Last Contact Time</Typography>
                  <Typography variant="body2">
                    {device?.last_contact_time
                      ? format(
                          new Date(device?.last_contact_time),
                          "MMMM dd, yyyy h:mm a"
                        )
                      : "Unknown"}
                  </Typography>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Uptime
                  </Typography>
                  <div style={{ minHeigh: 25 }}>
                    {open ? (
                      <Typography style={{ minHeight: 20 }}>...</Typography>
                    ) : (
                      <Uptime startTime={connectedStart} />
                    )}
                  </div>
                </CardContent>
              </Card>
            </Grid2>
          </Grid2>
        </div>
      )}
    </Container>
  );
};

export default Device;
