import WidgetsIcon from "@mui/icons-material/Widgets";
import { FileText, Package, Plus, ShoppingCart, Users } from "react-feather";

import { Add } from "@mui/icons-material";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import FlightTakeoffOutlinedIcon from "@mui/icons-material/FlightTakeoffOutlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";

import useAuth from "../../hooks/useAuth";

function deviceSection(summary) {
  return [
    {
      href: "/devices/add",
      icon: Add,
      title: "Add Device",
      badge: null,
    },
    {
      href: "/devices",
      icon: InventoryIcon,
      title: "All Devices",
      badge:
        typeof summary?.devices === "number" ? summary.devices.toString() : "-",
    },
    // {
    //   href: "/devices/flipbit_3",
    //   icon: PrecisionManufacturingOutlinedIcon,
    //   title: "Device Page",
    //   badge:
    //     typeof summary?.devices === "number" ? summary.devices.toString() : "-",
    // },
  ];
}

function NavItems(summary) {
  return [
    {
      title: "Devices",
      pages: deviceSection(summary),
    },
  ];
}

export default NavItems;
