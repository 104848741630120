import {
  Alert,
  Box,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Button as MuiButton,
  Card as MuiCard,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";

import { Edit, Save } from "@mui/icons-material";
import { Formik } from "formik";
// import MuiPhoneNumber from "material-ui-phone-number";
import * as Yup from "yup";

const axios = require("axios").default;

function CustomerEdit() {
  const timeOut = (time) => new Promise((res) => setTimeout(res, time));
  const Spacer = styled.div(spacing);

  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    company_name: Yup.string(),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "too short")
      .max(10, "too long"),
    email: Yup.string().email().max(250),
    shipping_contact_name: Yup.string(),
    shipping_phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "too short")
      .max(10, "too long"),
    shipping_address: Yup.string(),
    shipping_street_address2: Yup.string(),
    shipping_city: Yup.string(),
    shipping_state: Yup.string(),
    shipping_zip: Yup.string(),
    shipping_contact_name: Yup.string(),
    shipping_phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "too short")
      .max(10, "too long"),
    billing_street_address: Yup.string(),
    billing_street_address2: Yup.string(),
    billing_city: Yup.string(),
    billing_state: Yup.string(),
    billing_zip: Yup.string(),

    // email: Yup.string().email().required("Required"),
    // password: Yup.string()
    //   .min(12, "Must be at least 12 characters")
    //   .max(255)
    //   .required("Required"),
    // confirmPassword: Yup.string().when("password", {
    //   is: (val) => (val && val.length > 0 ? true : false),
    //   then: Yup.string().oneOf(
    //     [Yup.ref("password")],
    //     "Both password need to be the same"
    //   ),
    // }),
  });

  const Card = styled(MuiCard)(spacing);

  const Button = styled(MuiButton)(spacing);

  const TextField = styled(MuiTextField)(spacing);
  const formRef = useRef();

  function BasicForm(props) {
    const handleSubmit = async (
      values,
      { resetForm, setErrors, setStatus, setSubmitting }
    ) => {
      try {
        const resp = await axios.put("/customers/" + id + "/", {
          ...values,
        });
        setInitialValues(resp.data);
        setEdit(false);

        resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      } catch (error) {
        console.log("Error!, ", error);

        setStatus({ sent: false });
        setErrors({ submit: error.message });
        setSubmitting(false);
      }
    };

    return (
      <Formik
        innerRef={formRef}
        initialValues={props.initialValues}
        enableReinitialize
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Customer Details
              </Typography>
              <Typography variant="body2" gutterBottom>
                fill in the following customer information
              </Typography>

              {status && status.sent && (
                <Alert severity="success" my={3}>
                  New customer has been submitted successfully!
                </Alert>
              )}

              {isSubmitting ? (
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
              ) : (
                <form id="editCustomer" onSubmit={handleSubmit}>
                  <TextField
                    name="name"
                    label="Name"
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <TextField
                    name="company"
                    label="Company"
                    value={values.company_name}
                    error={Boolean(touched.company_name && errors.company_name)}
                    fullWidth
                    helperText={touched.company_name && errors.company_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  {/* <MuiPhoneNumber
                    name="phone"
                    label="Phone Number"
                    value={values.phone}
                    data-cy="number"
                    defaultCountry={"us"}
                    onChange={(e) => setFieldValue("phone", e)}
                    onBlur={handleBlur}
                    helperText={touched.phone && errors.phone}
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    type="tel"
                    variant="outlined"
                    sx={{ my: 4 }}
                  /> */}
                  <TextField
                    name="email"
                    label="Email"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    type="email"
                    my={4}
                  />
                  <TextField
                    name="shipping_contact"
                    label="Shipping Contact"
                    value={values.shipping_contact_name}
                    error={Boolean(
                      touched.shipping_contact_name &&
                        errors.shipping_contact_name
                    )}
                    fullWidth
                    helperText={
                      touched.shipping_contact_name &&
                      errors.shipping_contact_name
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  {/* <MuiPhoneNumber
                    name="shipping_phone"
                    label="Shipping Phone"
                    value={values.shipping_phone}
                    data-cy="number"
                    defaultCountry={"us"}
                    onChange={(e) => setFieldValue("shipping_phone", e)}
                    onBlur={handleBlur}
                    helperText={touched.shipping_phone && errors.shipping_phone}
                    error={Boolean(
                      touched.shipping_phone && errors.shipping_phone
                    )}
                    fullWidth
                    type="tel"
                    variant="outlined"
                    sx={{ my: 4 }}
                  /> */}
                  {/* TODO Break this out into subfields, aka street, city, state, zip etc */}
                  <TextField
                    name="shipping_street_address"
                    label="Shipping Address"
                    value={values.shipping_street_address}
                    error={Boolean(
                      touched.shipping_street_address &&
                        errors.shipping_street_address
                    )}
                    fullWidth
                    helperText={
                      touched.shipping_street_address &&
                      errors.shipping_street_address
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <TextField
                    name="shipping_street_address2"
                    label="Shipping Street Address2"
                    value={values.shipping_steet_address2}
                    error={Boolean(
                      touched.shipping_steet_address2 &&
                        errors.shipping_steet_address2
                    )}
                    fullWidth
                    helperText={
                      touched.shipping_steet_address2 &&
                      errors.shipping_steet_address2
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <TextField
                    name="shipping_city"
                    label="Shipping City"
                    value={values.shipping_city}
                    error={Boolean(
                      touched.shipping_city && errors.shipping_city
                    )}
                    fullWidth
                    helperText={touched.shipping_city && errors.shipping_city}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <TextField
                    name="shipping_state"
                    label="Shipping State"
                    value={values.shipping_state}
                    error={Boolean(
                      touched.shipping_state && errors.shipping_state
                    )}
                    fullWidth
                    helperText={touched.shipping_state && errors.shipping_state}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <TextField
                    name="shipping_zip"
                    label="Shipping Zip"
                    value={values.shipping_zip}
                    error={Boolean(touched.shipping_zip && errors.shipping_zip)}
                    fullWidth
                    helperText={touched.shipping_zip && errors.shipping_zip}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />

                  <TextField
                    name="billing_contact_name"
                    label="Billing Contact"
                    value={values.billing_contact_name}
                    error={Boolean(
                      touched.billing_contact_name &&
                        errors.billing_contact_name
                    )}
                    fullWidth
                    helperText={
                      touched.billing_contact_name &&
                      errors.billing_contact_name
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  {/* <MuiPhoneNumber
                    name="billing_phone"
                    label="Billing Phone"
                    value={values.billing_phone}
                    data-cy="number"
                    defaultCountry={"us"}
                    onChange={(e) => setFieldValue("billing_phone", e)}
                    onBlur={handleBlur}
                    helperText={touched.billing_phone && errors.billing_phone}
                    error={Boolean(
                      touched.billing_phone && errors.billing_phone
                    )}
                    fullWidth
                    type="tel"
                    variant="outlined"
                    sx={{ my: 4 }}
                  /> */}
                  {/* TODO Break this out into subfields, aka street, city, state, zip etc */}
                  <TextField
                    name="billing_street_address"
                    label="Billing Steet Address"
                    value={values.billing_steet_address}
                    error={Boolean(
                      touched.billing_steet_address &&
                        errors.billing_steet_address
                    )}
                    fullWidth
                    helperText={
                      touched.billing_steet_address &&
                      errors.billing_steet_address
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <TextField
                    name="billing_street_address2"
                    label="Billing Street Address2"
                    value={values.billing_steet_address2}
                    error={Boolean(
                      touched.billing_steet_address2 &&
                        errors.billing_steet_address2
                    )}
                    fullWidth
                    helperText={
                      touched.billing_steet_address2 &&
                      errors.billing_steet_address2
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <TextField
                    name="billing_city"
                    label="Billing City"
                    value={values.billing_city}
                    error={Boolean(touched.billing_city && errors.billing_city)}
                    fullWidth
                    helperText={touched.billing_city && errors.billing_city}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <TextField
                    name="billing_state"
                    label="Billing State"
                    value={values.billing_state}
                    error={Boolean(
                      touched.billing_state && errors.billing_state
                    )}
                    fullWidth
                    helperText={touched.billing_state && errors.billing_state}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <TextField
                    name="billing_zip"
                    label="Billing Zip"
                    value={values.billing_zip}
                    error={Boolean(touched.billing_zip && errors.billing_zip)}
                    fullWidth
                    helperText={touched.billing_zip && errors.billing_zip}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                  >
                    Save changes
                  </Button>
                </form>
              )}
            </CardContent>
          </Card>
        )}
      </Formik>
    );
  }

  const [isLoading, setLoading] = React.useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    company_name: "",
    phone: "",
    email: "",
    shipping_contact_name: "",
    shipping_phone: "",
    shipping_street_address: "",
    shipping_street_address2: "",
    shipping_city: "",
    shipping_state: "",
    shipping_zip: "",
    billing_contact_name: "",
    billing_phone: "",
    billing_street_address: "",
    billing_street_address2: "",
    billing_city: "",
    billing_state: "",
    billing_zip: "",
  });

  useEffect(() => {
    const fetchCustomer = axios
      .get("/customers/" + id)
      .then((response) => {
        setInitialValues(response.data);
        setLoading(false);
      })
      .catch((errors) => {
        console.log(errors);
      });
  }, []);

  return (
    <div>
      <Card mb={6}>
        <CardContent>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h1" gutterBottom>
              Customer
            </Typography>
            <div>
              {edit ? (
                <IconButton
                  aria-label="details"
                  size="large"
                  type="submit"
                  form="editCustomer"
                  onClick={() => {
                    if (formRef.current) {
                      formRef.current.handleSubmit();
                      // setEdit(!edit);
                    }
                  }}
                >
                  <Save />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="details"
                  size="large"
                  onClick={() => {
                    setEdit(!edit);
                  }}
                >
                  <Edit />
                </IconButton>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
      {edit ? (
        <Card mb={6}>
          <CardContent>
            <BasicForm initialValues={initialValues} />
          </CardContent>
        </Card>
      ) : (
        <div>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={12}>
              <Card mb={6}>
                <CardContent>
                  <Typography variant="h6">Contact</Typography>
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <Spacer mb={4} />

                      <Typography>{initialValues?.company_name}</Typography>
                      <Typography>{initialValues?.name}</Typography>
                      <Typography>{initialValues?.phone}</Typography>
                      <Typography>{initialValues?.email}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Card mb={6}>
                <CardContent>
                  <Typography variant="h6">Ship To</Typography>
                  <Grid container spacing={6} style={{ marginTop: 5 }}>
                    <Grid item>
                      <div>
                        <Typography>
                          {initialValues.shipping_contact_name}
                        </Typography>
                      </div>
                      <div>
                        <Typography>{initialValues.shipping_phone}</Typography>
                      </div>
                      <div>
                        <Typography>
                          {initialValues.shipping_street_address}
                        </Typography>
                      </div>
                      <div>
                        <Typography>
                          {initialValues.shipping_street_address2}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div style={{ marginRight: 5 }}>
                          <Typography>
                            {initialValues.shipping_city},
                          </Typography>
                        </div>
                        <Typography>{initialValues.shipping_state}</Typography>
                      </div>
                      <div>
                        <Typography>{initialValues.shipping_zip}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Card mb={6}>
                <CardContent>
                  <Typography variant="h6">Bill To</Typography>
                  <Grid container spacing={6} style={{ marginTop: 5 }}>
                    <Grid item>
                      <div>
                        <Typography>
                          {initialValues.billing_contact_name}
                        </Typography>
                      </div>
                      <div>
                        <Typography>{initialValues.billing_phone}</Typography>
                      </div>
                      <div>
                        <Typography>
                          {initialValues.billing_street_address}
                        </Typography>
                      </div>
                      <div>
                        <Typography>
                          {initialValues.billing_street_address2}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div style={{ marginRight: 5 }}>
                          <Typography>{initialValues.billing_city},</Typography>
                        </div>
                        <Typography>{initialValues.billing_state}</Typography>
                      </div>
                      <div>
                        <Typography>{initialValues.billing_zip}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Card mb={6}>
                <CardContent>
                  <Typography variant="h6">Actions</Typography>
                  {!edit && (
                    <div
                      style={{
                        marginTop: 20,
                        display: "flex",
                        justifyContent: "left",
                        maxWidth: 800,
                        alignSelf: "center",
                        justifySelf: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          navigate("/quote/create", {
                            state: { customer: initialValues },
                          });
                        }}
                        style={{
                          marginRight: 10,
                        }}
                      >
                        Create Quote
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          navigate("/order/create", {
                            state: { customer: initialValues },
                          });
                        }}
                      >
                        Create Order
                      </Button>
                    </div>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default CustomerEdit;
