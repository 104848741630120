import React, { useEffect, useState } from "react";
import {
  Box,
  Accordion,
  Heading,
  Skeleton,
  Stack,
  AccordionItem,
  AccordionButton,
  useToast,
  Container,
  useColorModeValue,
  Grid,
  useTheme,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Typography,
} from "@mui/material";
import DeviceAccordionItem from "./DeviceAccordionItem";
import { useGetDevicesQuery } from "../../redux/slices/djangoApi";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Toast from "../Toast";
import DeviceMap from "./DeviceMap";
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const commands = [
  {
    name: "Reboot",
    description: "Reboot the device",
    callback: () => {
      // Add logic to reboot the device
    },
  },
  {
    name: "Toggle Power",
    description: "Toggle the power state of the device",
    callback: () => {
      // Add logic to toggle the power state of the device
    },
  },
  {
    name: "Update Firmware",
    description: "Schedule the power state of the device",
    callback: () => {
      // Add logic to schedule the power state of the device
    },
  },
];

const DevicesList = () => {
  const toast = Toast();
  const { data: devices, error, isLoading } = useGetDevicesQuery();
  console.log("|| DEVICES: ", devices);
  const theme = useTheme();
  const navigate = useNavigate();
  const backgroundColor =
    theme.palette.mode === "dark"
      ? theme.palette.grey[800]
      : theme.palette.common.white;

  const handleAction = (deviceId, callback) => {
    callback();
    toast({
      title: `Action dispatched to ${deviceId}`,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [modifiedDevices, setModifiedDevices] = useState([]);

  useEffect(() => {
    const modded = devices?.results?.map((device) => {
      const rand1 = getRandomNumberBetween(-2, 2);
      const rand2 = getRandomNumberBetween(-2, 2);
      const lng = -94.006 + rand1;
      const lat = 42.7128 + rand2;
      console.log(
        "|| rand1: ",
        rand1,
        " rand 2: ",
        rand2,
        " lng: ",
        lng,
        " lat: ",
        lat
      );
      return { ...device, coordinates: [lng, lat] };
    });
    setModifiedDevices(modded);
  }, [devices]);

  const handleSelectItem = (id) => {
    setSelectedItems((prev) =>
      prev.includes(id) ? prev.filter((device) => device !== id) : [...prev, id]
    );
  };

  function getRandomNumberBetween(min, max) {
    return Math.random() * (max - min) + min;
  }

  useEffect(() => {
    const filtered =
      searchTerm.length < 1
        ? modifiedDevices
        : modifiedDevices?.filter((device) =>
            device?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
          );

    setFilteredData(filtered);
  }, [modifiedDevices, searchTerm]);

  const handleAccordionChange = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? id : null);
  };

  // const [modifiedDeviceList, setModifiedDeviceList] = useState([]);

  // useEffect(() => {
  //   const newDevices = filteredData?.map((device) => {
  //     return { ...device, coordinates: [0.0, 0.0] };
  //   });

  //   console.log(newDevices);
  //   setModifiedDeviceList(newDevices);
  // }, [filteredData]);

  return (
    <Grid minH="100vh" p={4} mt={4} backgroundColor="white">
      <Container
        maxW="container.lg"
        bg={backgroundColor}
        p={8}
        rounded="lg"
        shadow="lg"
      >
        <Typography variant="h1" gutterBottom>
          Manage Devices
        </Typography>
        {isLoading ? (
          <div>
            <TextField
              label="Search"
              variant="outlined"
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ marginBottom: "16px", marginTop: "16px", width: "100%" }}
            />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox checked={0} />
                    </TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array(3)
                    .fill("")
                    .map((_, index) => (
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox checked={0} />
                        </TableCell>
                        <TableCell colSpan={4} padding="none">
                          <Skeleton key={index} m={4}>
                            <DeviceAccordionItem
                              index={index}
                              device={{}}
                              commands={commands}
                              handleAction={handleAction}
                            />
                          </Skeleton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <div>
            <TextField
              label="Search"
              variant="outlined"
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ marginBottom: "16px", marginTop: "16px", width: "100%" }}
            />

            <DeviceMap devices={filteredData} />

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedItems?.length === filteredData?.length}
                        onChange={() =>
                          setSelectedItems(
                            selectedItems?.length === filteredData?.length
                              ? []
                              : filteredData?.map(
                                  (device) => device.serial_number
                                )
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData?.length > 0 ? (
                    filteredData?.map((device, index) => (
                      <React.Fragment key={device.serial_number}>
                        <TableRow>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedItems.includes(
                                device.serial_number
                              )}
                              onChange={() =>
                                handleSelectItem(device.serial_number)
                              }
                            />
                          </TableCell>
                          <TableCell colSpan={4} padding="none">
                            <DeviceAccordionItem
                              index={index}
                              key={device.serial_number}
                              device={device}
                              commands={commands}
                              handleAction={handleAction}
                            />
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography>
                          No devices associated with your account.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </Container>
    </Grid>
  );
};

export default DevicesList;
